import { createApp, defineAsyncComponent } from "vue";
import BeckButton from "beckOnlineElements/BeckButton";
import BeckDropdown from "beckOnlineElements/BeckDropdown";
import BeckLink from "beckOnlineElements/BeckLink";
import { useStore } from "store/storePlugin";
import { store } from "store/store";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import router from "./router";
import "./middleware/authentication";
import { setupMiddlewareLanguage } from "./middleware/language";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faSquareFacebook, faLinkedin, faSquareXTwitter } from "@fortawesome/free-brands-svg-icons";
import {
  faSquareRss,
  faWandMagicSparkles,
  faMagnifyingGlass,
  faPaintBrush,
  faBell,
  faLock,
  faArrowLeft,
  faHome,
  faUser,
  faAnglesLeft,
  faAnglesRight,
  faClipboardList,
  faListOl,
  faEnvelope,
  faBook,
  faBookOpen,
  faUserLock,
  faFileLines,
  faPalette,
  faEye,
  faDiceD20,
  faLanguage,
  faHouse,
  faCartShopping,
  faFolderOpen,
  faGear,
  faPhone,
  faGlobe,
  faSortUp,
  faSortDown,
  faPen,
  faTrash,
  faCircleQuestion,
  faChalkboardUser,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import { dom, config, library } from "@fortawesome/fontawesome-svg-core";
import "./style.scss";
import { i18n } from "./plugins/i18n";
import VueMatomo from "vue-matomo";
import "bootstrap";
import { matomoSiteId } from "../app.config";

setupMiddlewareLanguage(i18n);
const BeckTextInput = defineAsyncComponent(() => import("beckOnlineElements/BeckTextInput"));
const BeckOption = defineAsyncComponent(() => import("beckOnlineElements/BeckOption"));
const BeckDoubleBubble = defineAsyncComponent(() => import("beckOnlineElements/BeckDoubleBubble"));
const BeckModal = defineAsyncComponent(() => import("beckOnlineElements/BeckModal"));
const App = defineAsyncComponent(() => import("./App.component.vue"));
const Main = defineAsyncComponent(() => import("./layouts/main.vue"));
const Main_Sidebar = defineAsyncComponent(() => import("./layouts/main-sidebar.vue"));
const Error = defineAsyncComponent(() => import("./layouts/error.vue"));
const app = createApp(App);
const beckOption = defineAsyncComponent(() => import("beckOnlineElements/BeckOption"));

const options: PluginOptions = {
  position: POSITION.TOP_RIGHT,
  timeout: 2500
};

config.autoAddCss = false;
library.add(
  faSquareFacebook,
  faLinkedin,
  faSquareXTwitter,
  faSquareRss,
  faWandMagicSparkles,
  faMagnifyingGlass,
  faPaintBrush,
  faBell,
  faLock,
  faArrowLeft,
  faHome,
  faUser,
  faAnglesLeft,
  faAnglesRight,
  faClipboardList,
  faListOl,
  faEnvelope,
  faBook,
  faBookOpen,
  faUserLock,
  faFileLines,
  faPalette,
  faEye,
  faDiceD20,
  faLanguage,
  faHouse,
  faCartShopping,
  faFolderOpen,
  faGear,
  faPhone,
  faGlobe,
  faSortUp,
  faSortDown,
  faPen,
  faTrash,
  faCircleQuestion,
  faChalkboardUser,
  faCaretDown
);
dom.watch();

app.component("main-layout", Main);
app.component("main-sidebar-layout", Main_Sidebar);
app.component("error-layout", Error);
app.component("BeckOption", beckOption);
app.component("BeckButton", BeckButton);
app.component("BeckDropdown", BeckDropdown);
app.component("BeckOption", BeckOption);
app.component("BeckLink", BeckLink);
app.component("BeckTextInput", BeckTextInput);
app.component("BeckModal", BeckModal);
app.component("BeckDoubleBubble", BeckDoubleBubble);
app.use(router);
app.use(Toast, options);
app.use(useStore(store));
app.use(VueMatomo, {
  host: "https://beck.matomo.cloud",
  siteId: matomoSiteId
});

app.use(i18n);
app.mount("#app");
