import { nextTick, isRef } from 'vue';
import { createI18n } from 'vue-i18n';
import de from '../../locales/de.json';

import type {
  I18n,
  I18nOptions,
  Locale,
  VueI18n,
  Composer,
  I18nMode
} from 'vue-i18n'

export const SUPPORT_LOCALES = ['de', 'en'];

type VueI18n = typeof VueI18n;
type Composer = typeof Composer;
type I18nMode = typeof I18nMode;
type I18n = typeof I18n;
type Locale = typeof Locale;
type I18nOptions = typeof I18nOptions;

export const i18n = setupI18n({
  legacy: false,
  globalInjection: true,
  locale: 'de',
  fallbackLocale: 'de',
  messages: {
    de
  }
})

function isComposer(
  instance: VueI18n | Composer,
  mode: I18nMode
): instance is Composer {
  return mode === 'composition' && isRef(instance.locale);
}

export function getLocale(i18n: I18n): string {
  if (isComposer(i18n.global, i18n.mode)) {
    return i18n.global.locale.value;
  } else {
    return i18n.global.locale;
  }
}

export function setLocale(i18n: I18n, locale: Locale): void {
  if (isComposer(i18n.global, i18n.mode)) {
    i18n.global.locale.value = locale;
  } else {
    i18n.global.locale = locale;
  }
}

export function setupI18n(options: I18nOptions = { locale: 'de' }): I18n {
  const i18n = createI18n(options);
  setLocale(i18n, options.locale!);
  return i18n;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getResourceMessages = (r: any) => r.default || r

export async function loadLocaleMessages(i18n: I18n, locale: Locale) {
  // load locale messages
  const messages = await import(`../../locales/${locale}.json`).then(
    getResourceMessages
  );

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages);

  return nextTick();
}