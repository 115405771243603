import { SettingsBackendClient } from "../services/SettingsService.ts";
import { setLanguage } from "store/store";
import { useDispatch } from "store/storeHelper";
import router from "../router/index.ts";
import { baseUrl } from "../../app.config";
import { loadLocaleMessages } from '../plugins/i18n.ts';
import type { I18n } from 'vue-i18n';

type I18n = typeof I18n;

const _settings = new SettingsBackendClient(baseUrl);
export function setupMiddlewareLanguage(i18n: I18n) {
  router.beforeEach(async () => {
    try {
      const result = await _settings.userSettingsGetSettings("language", "1");
      const dispatch = useDispatch();
      if (result.status === 200) {
        if(result.result[0].value === 'en') {
          loadLocaleMessages(i18n, result.result[0].value);
        }
        dispatch(setLanguage(result.result[0].value));
        i18n.global.locale.value = result.result[0].value;
      } else if (result.status === 401) {
        dispatch(setLanguage('de'));
        i18n.global.locale.value = 'de';
      }
    } catch (err: any) {
      const dispatch = useDispatch();
      dispatch(setLanguage("de"));
      i18n.global.locale.value = 'de';
    }
  });

}
