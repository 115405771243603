import {
  SettingsBackendClient,
  SwaggerResponse,
  UserInfo,
} from '../services/SettingsService.ts';
import { setUserName, setUserInitials, setUserInfo } from 'store/store';
import { useDispatch } from 'store/storeHelper';
import router from '../router/index.ts';
import { baseUrl } from '../../app.config';
import { MatomoOptions } from '../composables/matomo.interface.ts';
import { RouteLocationNormalized } from 'vue-router';

const options: MatomoOptions = {
  visitBenutzerDimensionId: 1,
  actionBenutzerDimensionIdMatomo: 2,
  visitSessionDimensionIdMatomo: 3,
  actionReadableDimensionId: 4,
  actionOrganizationRootIdDimensionId: 5,
  userTypeName: '',
  sessionIdMatomo: '',
  readable: '',
  organizationRootId: '',
  customUrl: 'settings'
}
const _settings = new SettingsBackendClient(baseUrl);
const dispatch = useDispatch();
export const updateUserInfo = (userInfo: UserInfo) => {
  const name = userInfo.firstName + " " + userInfo.lastName;
  dispatch(setUserName(name));
  const firstInitial : string = userInfo.firstName ? userInfo.firstName[0] : "";
  const lastInitial : string = userInfo.lastName ? userInfo.lastName[0] : "";
  dispatch(setUserInitials(firstInitial + lastInitial));
  dispatch(setUserInfo(userInfo));
};

router.beforeEach(async (to: RouteLocationNormalized) => {
  const result: SwaggerResponse<UserInfo> =
    (await _settings.userSettingsGetUserInfo('1'));
  if (
    result.status === 200 &&
    result.result.knownUser
  ) {
    updateUserInfo(result.result);
    options.userTypeName = 'Eingeloggte Benutzer';
    options.customUrl = to.path;
    options.sessionIdMatomo = result.result.aspSessionId as string;
    let usercentrics: boolean = false;
    try {
      usercentrics = JSON.parse(localStorage.uc_settings).services[1].status;
    }
    catch {
      usercentrics = false;
    }

    if (usercentrics || location.hostname === 'localhost') {
      window._paq.push(['setCustomUrl', options.customUrl]);
      window._paq.push(['setUserId', options.userTypeName]);
      window._paq.push(['setCustomDimension', options.visitBenutzerDimensionId, options.userTypeName]);
      window._paq.push(['setCustomDimension', options.actionBenutzerDimensionIdMatomo, options.sessionIdMatomo]);
      window._paq.push(['setCustomDimension', options.visitSessionDimensionIdMatomo, options.sessionIdMatomo]);
      window._paq.push(['setCustomDimension', options.actionReadableDimensionId, options.readable]);
      window._paq.push(['setCustomDimension', options.actionOrganizationRootIdDimensionId, options.organizationRootId]);

      window._paq.push(['setCustomVariable', '1', 'Javascript', 'Aktiviert', 'visit']);
      window._paq.push(['setCustomVariable', '2', 'TrackingSource', 'client', 'visit']);
      window._paq.push(['trackPageView']);
      window._paq.push(['enableLinkTracking']);
    }
  } else if (
    location.origin !== 'https://localhost:44333' &&
    import.meta.env.VITE_ENVIRONMENT !== 'LOCAL'
  ) {
    location.href = import.meta.env.VITE_AUTH;
  } else {
    const anonymous = new UserInfo();
    anonymous.firstName = "";
    anonymous.lastName = "anonymous";
    updateUserInfo(anonymous);
  }
});
